import { useState } from 'react';
import { Case } from '../../../typings/Cases';
import { usePagedLoadedData } from '../../../toolympus/hooks/useLoadedData';


interface CaseSelectionData {
    isLoading: boolean;
    cases: Case[];
    filter: string | null;
    setFilter: (v: string) => void;
    doSearch: () => void;
}

export const useCaseSelection = (): CaseSelectionData => {
    const [filter, setFilter] = useState<string | null>(null);
    
    const [params, setParams] = useState<{ view: string, filter: string | null, "page-size": number}>({
        view: "user-cases-current",
        filter: null,
        "page-size": 100,
    });

    const data = usePagedLoadedData<Case>(`/api/case?${Object.entries(params).map(([k,v]) => `${k}=${v}`).join('&')}`, []);

    return {
        isLoading: data.isLoading,
        cases: data.data,
        filter,
        setFilter,
        doSearch: () => { setParams({ ...params, filter, view: filter && filter.length ? 'all' : "user-cases-current" })},
    };
}