import React, { useState } from 'react';
import styled from '@emotion/styled';
import { useHistory } from "react-router-dom";
import InnerRoutes from './InnerRoutes';
import { clearApiToken, decodeToken, getApiToken } from './toolympus/api/core';
import { SchemaProvider } from './toolympus/hooks/useSchema';
import { DictionariesProvider } from './toolympus/hooks/useDictionaries';
import { AppWrapper, AppContent, MenuItem, Routes } from './toolympus/components/frame/new';
import { DefaultUser, UserContext } from './toolympus/userContext/UserContext';
import { SideMenu } from './SideMenu';
import { MediaLibProvider } from './toolympus/components/medialib';
import { useLoginGuard } from './toolympus/hooks/auth/useLoginGuard';
import { HistoryFavorites } from './toolympus/components/HistoryFavorites/HistoryFavoritesContext';
import { SettingsProvider } from './components/SettingsPage';
import { UserSettingsProvider } from './toolympus/components/Settings/UserSettingsContext';
import { UpdateNotificationsDisplay } from './toolympus/components/Documentation/UpdatesNotifications';



const AppContentX = styled(AppContent)`
    overflow-x: auto;
    overflow-y: auto;
    display: block;
    max-height: 100vh;
`;


const userCanUpdateGlobalSettings = (u: DefaultUser | null | undefined) => u?.roles?.includes("admin") || false;


const AppRouter = () => {
    const token = getApiToken();
    const history = useHistory();
    const [user, setUser] = useState<DefaultUser>(token ? decodeToken(token) as DefaultUser : { _id: '', roles: [], email: '' });

    useLoginGuard("/login", { isAuthenticated: !!user?._id });
    
    const logout = () => {
        clearApiToken();
        history.push('/login');
    }

    const routesToRender = InnerRoutes.filter(r => r.resolveHidden === undefined || r.resolveHidden(user?.roles || []));
    const menuItems = routesToRender.filter(r => !r.hidden && r.title) as MenuItem[];

    
    return (
        <UserContext.Provider value={{ user: user, setUser: setUser }}>
          <UserSettingsProvider apiPath="/api/user-settings" canUpdateGlobal={userCanUpdateGlobalSettings}>
            <SchemaProvider>
                <DictionariesProvider>
                  <SettingsProvider>
                    <MediaLibProvider apiPath="/api/docs/media" spaceId="docs" protectedFiles getFilepath={mf => `/api/docs/media/${mf.space_id}/${mf._id}`}>
                        <HistoryFavorites lsKey='_raca_history_favs'>
                          <UpdateNotificationsDisplay />
                            <AppWrapper>
                                <SideMenu
                                    menuItems={menuItems}
                                    logout={logout}
                                    />
                                <AppContentX>
                                    <Routes routes={routesToRender} notFoundRedirect="/home" />
                                </AppContentX>
                            </AppWrapper>
                        </HistoryFavorites>
                    </MediaLibProvider>
                  </SettingsProvider>
                </DictionariesProvider>
            </SchemaProvider>
          </UserSettingsProvider>
        </UserContext.Provider>
    );
}

export default AppRouter;
