import React, { useState } from 'react';
import { IconButton } from '@mui/material';
import { Add } from '@mui/icons-material';
import { EmptyState } from '../../../toolympus/components/primitives/EmptyState';
import { LoadingIndicator } from '../../../toolympus/components/primitives/LoadingIndicator';
import { CaseListData, CaseListType } from '../caseData';
import NewCaseContainer from '../NewCaseContainer';
import { CasesListTableOnly, StatusElement } from './CasesList';
import { PagingControl } from '../../../toolympus/components/primitives/PagingControl';
import { Form } from '../../../toolympus/components/primitives/Forms';
import { FieldSettingsPopupButton, useFields } from '../../../toolympus/components/schemed';
import { mergeSchema, useSchema } from '../../../toolympus/hooks/useSchema';
import { useTableComments } from '../../Comments';
import { useUser } from '../../../toolympus/userContext/UserContext';
import { Buttons, OccupyFreeSpace } from '../../../toolympus/components/primitives/ActionRow';
import { SearchField } from '../../../toolympus/components/primitives/SearchField';
import { ButtonOptionPicker } from '../../../toolympus/components/primitives/ButtonOptionPicker';
import { InsetPanel } from '../../Common/UI';
import { PluggableDocumentationButton } from '../../../toolympus/components/Documentation';

interface Props {
    cases: CaseListData;
}

export const CasesListPage = ({ cases }: Props) => {
    const [isNewFormActive, setIsNewFormActive] = useState(false);

    const schemas = useSchema();
    const schema = mergeSchema(schemas.case, {
        claimant_title: { label: "Истец" },
        respondant_title: { label: "Ответчик" },
        arbitration_start_date: { label: "Начало" },
        arbitration_end_date: { label: "Окончание" },
    });

    const fields = useFields({
        defaultFields: [
            "casenbr",
            "rules",
            "case_type",
            "claimant_title",
            "respondant_title",
            "arbitration_start_date",
            "arbitration_end_date",
            "status",
        ],
        schema,
        storageKey: "fields_cases",
        extraSettings: {
            status: { element: StatusElement }
        },
        blacklist: [
          "practice_ids",
        ],
    });

    const comments = useTableComments('case');

    const user = useUser();

    const userTabs: [string, CaseListType][] = [
        ["Мои текущие", CaseListType.MyCurrent],
        ["Мои", CaseListType.My],
        ["Все", CaseListType.Main],
    ];

    const adminTabs: [string, CaseListType][] = [
        ["Мои текущие", CaseListType.MyCurrent],
        ["Мои", CaseListType.My],
        ["Все (не скрытые)", CaseListType.Main],
        ["Не проверенные", CaseListType.Unchecked],
        ["Скрытые", CaseListType.Hidden],
    ];

    const tabs = (user.user?.roles || []).find(r => r === "admin") ? adminTabs : userTabs;
    
    return (
        <Form title="Дела"
            headerItems={
              <Buttons fullWidth alignItems="flex-end">
                <IconButton color='primary' onClick={() => setIsNewFormActive(true)}><Add /></IconButton>
                {cases.isLoading && <LoadingIndicator sizeVariant="m" />}
                
                <OccupyFreeSpace />
                
                <SearchField
                    autoFocus
                    filter={cases.filter}
                    setFilter={cases.setFilter}
                    noButton
                />
                <ButtonOptionPicker
                    options={tabs}
                    selected={cases.listtype}
                    setSelected={cases.setListType} />

                <FieldSettingsPopupButton fieldsSettings={fields} />
                <PluggableDocumentationButton documentationKey="cases" />
              </Buttons>}>
            
            <NewCaseContainer isActive={isNewFormActive} setIsActive={setIsNewFormActive} />

            <InsetPanel>
              <PagingControl data={cases} totalRecordsText="Всего дел: {0}" />
            </InsetPanel>
                        
            <CasesListTableOnly
                cases={cases.data}
                sorting={cases.sorting}
                fieldssettings={fields}
                comments={comments}
                emptyState={<EmptyState text={<p style={{ textAlign: 'center'}}>
                    Уточните критерии поиска и нажмите кнопку "Загрузить"<br/>
                    <i>Поиск выполняется по номеру дела и названиям сторон<br/>
                    Для загрузки всех дел просто нажмите "Загрузить"</i>
                    </p>} />} />
            
        </Form>
    );
}
