import React, { useState } from 'react';
import styled from '@emotion/styled';
import Logo from './logo.svg';
import LogoS from './risa_cut.svg';
import { Sidebar, Menu, DrawerPaperView, MenuItem, Anchor, MenuProps } from './toolympus/components/frame/new';
import { IconButton, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { ArrowForwardIos, ArrowBackIos, ExitToApp, Menu as MenuIcon } from '@mui/icons-material';
import { useMediaBreakpoint } from './toolympus/components/primitives';

const LogoImg = styled.img`
    width: 100%;
    box-sizing: border-box;
    padding: 2rem 1.5rem;
`;
LogoImg.defaultProps = { src: Logo, alt: 'RAC' };

const LogoSImg = styled.img`
    width: 60px;
    max-width: 100%;
    box-sizing: border-box;
    padding: 1rem 0.25rem;
`;
LogoSImg.defaultProps = { src: LogoS, alt: 'RAC' };

const DrawerPaperViewX = styled(DrawerPaperView)`
    & > .MuiPaper-root {
        box-shadow: 0px 0px 8px -2px #333333aa;
        z-index: 400;
        background: #ffffff;
    }

    & .MuiDrawer-paper{    
        color: ${props => props.theme.palette.grey[800]};
        background-color: #ffffff;
    }
    
    & .MuiSvgIcon-root {
        color: ${props => props.theme.palette.grey[600]};
    }
`;

const MenuMobileButton = styled(IconButton)`
    position: fixed;
    bottom: 1rem;
    left: 1rem;
    z-index: 350;
    background: #ffffff80;
    box-shadow: 0px 0px 8px -2px #333333aa;

    ${props => props.theme.breakpoints.up("sm")} {
        display: none;
    }
`;

const useMenuCollapsed = () => {
    const lsk = "__raca_menu_collapsed";
    const [isCollapsed, update] = useState<boolean>(localStorage.getItem(lsk) === "t");
    return {
        isCollapsed,
        setIsCollapsed: (v: boolean) => {
            update(v);
            localStorage.setItem(lsk, v ? "t" : "f");
        },
    }
}

interface Props {
    menuItems: MenuItem[];
    logout: () => void;
}

export const SideMenu = (props: Props) => {
    const { menuItems, logout } = props;

    const menuState = useMenuCollapsed();

    const isMobile = useMediaBreakpoint("down", "sm");

    const [isOpen, setIsOpen] = useState<boolean>(!isMobile);

    const bottom = (
        <List>
            {!isMobile && <ListItem button key='collapse' onClick={() => menuState.setIsCollapsed(!menuState.isCollapsed)}>
                <ListItemIcon title={menuState.isCollapsed ? "развернуть меню" : "свернуть меню"}>
                    {menuState.isCollapsed ? <ArrowForwardIos /> : <ArrowBackIos />}
                </ListItemIcon>
            </ListItem>}
            <ListItem button key='logout' onClick={logout}>
                <ListItemIcon title="Выход"><ExitToApp /></ListItemIcon>
                {!menuState.isCollapsed && <ListItemText primary='Выход' />}
            </ListItem>
        </List>
    );

    const AnchorX: MenuProps["itemAnchorComponent"] = props => (
        <Anchor
            {...props}
            onClick={() => {
                if(isMobile) {
                    setIsOpen(false);
                }
            }}
            />);

    return (<>
        <MenuMobileButton className="mobilemenubutton" onClick={() => setIsOpen(true)}>
            <MenuIcon />
        </MenuMobileButton>
        <Sidebar
            open={isOpen}
            onClose={() => setIsOpen(false)}
            top={menuState.isCollapsed ? <LogoSImg /> : <LogoImg />}
            menu={<Menu iconsOnly={menuState.isCollapsed} menuItems={menuItems} itemAnchorComponent={AnchorX} />}
            bottom={bottom}
            drawerPaperComponent={DrawerPaperViewX}
            width={menuState.isCollapsed ? 60 : undefined}
        />
        </>
    );
}
