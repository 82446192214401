import React, { useState } from 'react';
import { Button, Dialog, DialogContent, IconButton, InputAdornment } from '@mui/material';
import { CallMade, Search } from '@mui/icons-material';
import { Link } from '../../toolympus/components/primitives/Common.styles';
import { FormControl, NewTabLinkProps, TableForFields } from '../../toolympus/components/schemed';
import { FieldType, Schema, useSchema } from '../../toolympus/hooks/useSchema';
import { useCaseNbrs, useCaseSelection } from './caseData';
import { StatusElement } from './presentation/CasesList';
import { Case } from '../../typings/Cases';
import { ActionRow, OccupyFreeSpace } from '../../toolympus/components/primitives/ActionRow';
import { LoadingIndicator } from '../../toolympus/components/primitives/LoadingIndicator';
import { SearchField } from '../../toolympus/components/primitives/SearchField';
import { FormSmallerTitle } from '../../toolympus/components/primitives/Forms';

interface Props {
    field: string;
    schema: Schema;
    case_id?: string | null;
    setCaseId: (v: string | null, c: Case | null) => void;
    disabled?: boolean;
    noArea?: boolean;
}

export const CaseSelect = (props: Props) => {
    const { field, schema, case_id, setCaseId, disabled } = props;
    const caseNbrs = useCaseNbrs();
    const { casenbr } = (case_id && caseNbrs.data.find(c => c._id === case_id)) || { casenbr: ''};
    const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);

    return (<>
        <FormControl
            field={field}
            schema={{ ...schema[field], type: FieldType.text}}
            onChange={() => {}}
            row={{ [field]: casenbr }}
            key={field}
            extraProps={{
                readOnly: true,
                withArea: !props.noArea,
                controlProps: {
                  endAdornment: <InputAdornment position="end">
                    {case_id && <Link to={`/case/${case_id}`} {...NewTabLinkProps}><IconButton size="small"><CallMade /></IconButton> </Link>}
                    {!disabled && <IconButton size="small" onClick={() => setIsPopupOpen(true)}><Search /></IconButton>}
                  </InputAdornment>
                }
            }}
            />
        <CaseSearchPopup
            isOpen={isPopupOpen}
            close={() => setIsPopupOpen(false)}
            selectedCaseId={case_id}
            selectCase={setCaseId} />
    </>);
}

interface SearchPopupProps {
    isOpen: boolean;
    close: () => void;
    selectedCaseId?: string | null;
    selectCase: (caseId: string, c: Case | null) => void;
}

const CaseSearchPopup = ({ isOpen, close, selectedCaseId, selectCase }: SearchPopupProps) => {
    const { case: schema } = useSchema();
    const data = useCaseSelection();

    const makeSelection = (c: Case) => { selectCase(c._id, c); close(); }

    return (
        <Dialog open={isOpen} onClose={close} fullWidth maxWidth="lg">
            <DialogContent>
                <ActionRow>
                    <FormSmallerTitle>Выбор дела</FormSmallerTitle>
                    {data.isLoading && <LoadingIndicator sizeVariant="m" />}
                    <OccupyFreeSpace />
                    <SearchField
                        autoFocus
                        filter={data.filter || ""}
                        setFilter={data.setFilter}
                        doSearch={data.doSearch}
                        buttonLabel=" "
                        />
                </ActionRow>
                <TableForFields
                    schema={schema}
                    data={data.cases}
                    autoexpander={{ initialRows: 10, increment: 10 }}
                    fields={[
                        ["casenbr"],
                        ["rules"],
                        ["case_type"],
                        ["claimant_title"],
                        ["respondant_title"],
                        ["arbitration_start_date"],
                        ["status", { element: StatusElement }],
                    ]}
                    fieldLink={f => f === "casenbr" ? r => `/case/${r._id}` : null}
                    fieldLinkNewTab
                    onDblClick={makeSelection}
                    rowButtons={r => <>
                        <Button color="primary" onClick={() => makeSelection(r)} disabled={r._id === selectedCaseId}>
                            {r._id === selectedCaseId ? "выбрано" : "выбрать"}
                        </Button></>}
                    />
            </DialogContent>
        </Dialog>
    );
}
