import { useEffect, useMemo, useState } from "react";
import { NewItemProps, useNewItem } from "../api/useNewItem";
import { FieldFilters, FieldFiltersQueryParam, useFieldFilters } from "../components/schemed/Filtering";
import { TextFilter, useTextFilter } from "../components/schemed/Filtering/useTextFilter";
import { SortingConfig } from "./useFieldSorting";
import { LoadedData, useLoadedData } from "./useLoadedData";
import { ChunkedLoadedListData, useChunkedLoadedList } from "./useLoadedList";
import { Schema } from "./useSchema";
import { base64encode } from "../api/base64";

export interface SmartListConfig<T> {
  viewDefault?: string;
  lsKeysPrefix?: string;
  fieldFiltersSettingsKey?: string;

  noView?: boolean;
  noLimit?: boolean;
  noApiFieldFiltering?: boolean;
  extraParams?: Record<string, any>;
  sorting?: SortingConfig;
  schema: Schema;
  noLoad?: boolean;
  noCount?: boolean;
  skipTextFilter?: boolean;
  textFilterApi?: {
    paramName: string;
    throttle?: number;
  }

  textFilterFn?: (r: T) => string;
  newRecordDefault: Partial<T>;
  onNewRecordSaved?: (r: T) => void;
}

export interface SmartListData<T> extends ChunkedLoadedListData<T> {
  schema: Schema;
  filtering: FieldFilters;
  filter: TextFilter<T>;
  count: LoadedData<{ total_records: number }>;

  newRecord: NewItemProps<Partial<T>, T>;
}

const ConstantlyEmptyStr = () => "";

export const useSmartList = <T extends Record<string, any>>(apiPath: string, cfg: SmartListConfig<T>): SmartListData<T> => {
  const [fieldFiltersParam, setFieldFiltersParam] = useState<string>("");
  const [textFilterApi, setTextFilterApi] = useState<string>("");

  const extraParams = cfg.extraParams || {};
  if(fieldFiltersParam) {
    extraParams[FieldFiltersQueryParam] = fieldFiltersParam;
  }
  if(cfg.textFilterApi?.paramName && textFilterApi) {
    extraParams[cfg.textFilterApi.paramName] = textFilterApi;
  }

  const data = useChunkedLoadedList<T>(apiPath, {
    sorting: cfg.sorting,
    viewDefault: cfg.viewDefault,
    viewLSKey: cfg.lsKeysPrefix ? `${cfg.lsKeysPrefix}_view` : undefined,
    extraParams,
    noLoad: cfg.noLoad,
  });

  const count = useLoadedData<{ total_records: number }>(`${apiPath}/count?${data.queryNoLimit}`, { total_records: 0 }, !cfg.noLimit && !cfg.noLoad && !cfg.noCount);

  const schema = cfg.schema;

  const filtering = useFieldFilters(schema, data.data, {
    storageKey: cfg.lsKeysPrefix ? `${cfg.lsKeysPrefix}_filtering` : undefined,
    userSettingsKey: cfg.fieldFiltersSettingsKey || (cfg.lsKeysPrefix ? `${cfg.lsKeysPrefix}_filtering` : undefined),
  });

  useEffect(() => {
    if(!cfg.noApiFieldFiltering) {
      const timeout = setTimeout(() => {
        setFieldFiltersParam(filtering.filtersParam ? encodeURIComponent(base64encode(JSON.stringify(filtering.filtersParam))) : "");
      }, 1000);
      return () => clearTimeout(timeout);
    }
  }, [filtering.filtersParam, cfg.noApiFieldFiltering]);


  const filter = useTextFilter<T>(cfg?.textFilterFn ||  ConstantlyEmptyStr);

  useEffect(() => {
    if(cfg.textFilterApi?.paramName) {
      const timeout = setTimeout(() => {
        setTextFilterApi(filter.filter);
      }, cfg.textFilterApi?.throttle || 500);
      return () => clearTimeout(timeout);
    }
  }, [filter.filter, cfg.textFilterApi?.paramName, cfg.textFilterApi?.throttle]);

  const newRecord = useNewItem<Partial<T>, T>(apiPath, cfg.newRecordDefault);

  const filteredData = useMemo(() => {
    const dataF1 = cfg.skipTextFilter ? data.data : filter.filterData(data.data);
    const dataF2 = cfg.noApiFieldFiltering ? dataF1.filter(filtering.filterFn) : dataF1;
    return dataF2;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.data, filter.filter, cfg.noApiFieldFiltering, cfg.skipTextFilter, filtering.filters, schema]);

  return {
    ...data,
    data: filteredData,
    schema,
    filtering,
    filter,
    count,

    newRecord: {
      ...newRecord,
      save: (c?: Partial<T>) => newRecord.save(c)
        .then(x => {
          if(cfg.onNewRecordSaved) {
            cfg.onNewRecordSaved(x);
          }
          data.reload();
          return x;
        }),
    },
  }
}
